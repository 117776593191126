export const getLambdaUrlAndAPIKey = (env) => {
  if (env === 'uat') {
    return {
      url: 'https://common-apis.uat.becklar.com',
      apiKey: 'K3XQgaHuF53l2dKPMxANn5uSS71uPZUo9Sn2GPoP',
    };
  } else if (env === 'dev') {
    return {
      url: 'https://common-apis.dev.becklar.com',
      apiKey: 'K3XQgaHuF53l2dKPMxANn5uSS71uPZUo9Sn2GPoP',
    };
  }
  return {
    url: 'https://common-apis.uat.becklar.com',
    apiKey: 'K3XQgaHuF53l2dKPMxANn5uSS71uPZUo9Sn2GPoP',
  };
};

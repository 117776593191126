import { registerApplication, start } from 'single-spa';
import { constructLayoutEngine, constructRoutes } from 'single-spa-layout';
import { Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js';
import axios from 'axios';
import { getLambdaUrlAndAPIKey } from './settings/settings';

declare global {
  interface Window {
    System: any;
  }
}

function showSpinner() {
  document
    .getElementById('loadingSpinner')
    ?.style.setProperty('display', 'block');
}

function hideSpinner() {
  document
    .getElementById('loadingSpinner')
    ?.style.setProperty('display', 'none');
}
function getEnvironment() {
  const origin = window.location.origin;

  if (origin.includes('localhost')) {
    return 'uat';
  } else if (origin.includes('apps.becklar.com')) {
    return 'prod';
  } else {
    return 'dev'; // Default or other environments if needed
  }
}

const env = getEnvironment();

const { url: menuLambdaUrl, apiKey: menuApiKey } = getLambdaUrlAndAPIKey(env);

let auth0Client: Auth0Client | null = null;
let userEmail: string | null = null;

const cachedAuth0Config = sessionStorage.getItem('auth0Config')
  ? JSON.parse(sessionStorage.getItem('auth0Config')!)
  : null;

const fetchAuth0Config = async () => {
  if (!cachedAuth0Config) {
    const response = await fetch(`${menuLambdaUrl}/app-credentials`, {
      method: 'GET',
      headers: { 'x-api-key': menuApiKey },
    });
    if (response.ok) {
      const config = await response.json();
      sessionStorage.setItem('auth0Config', JSON.stringify(config));
      return config;
    } else {
      throw new Error('Failed to fetch Auth0 config');
    }
  }
  return cachedAuth0Config;
};

const loadRequiredModules = () => System.import('@becklar/shell-ui');

async function createAuth0ClientInstance() {
  if (auth0Client) {
    return auth0Client;
  }

  const auth0Config = await fetchAuth0Config();

  console.time('createAuth0Client');
  auth0Client = await createAuth0Client({
    domain: auth0Config.Auth0_Domain,
    clientId: auth0Config.Auth0_ClientId,
    authorizationParams: {
      audience: auth0Config.Auth0_Audience,
      redirect_uri: window.location.origin,
      organization: auth0Config.Auth0_Organization,
      scope: auth0Config.Auth0_Scope,
    },
    cacheLocation: 'localstorage',
  });
  console.timeEnd('createAuth0Client');

  return auth0Client;
}

const updateImportMap = (applications: any[]) => {
  const imports: Record<string, string> = {};
  applications.forEach((app) => {
    const appId = app.AppIdentifier || app.Name;
    imports[appId] = localStorage.getItem(appId) || app.AppUrl;
  });
  window.System.addImportMap({ imports });
};

const fetchApplications = async (authToken: string) => {
  try {
    const response = await axios.get(`${menuLambdaUrl}/menu-items`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'x-api-key': menuApiKey,
      },
    });
    return response.data.filter((app) => app.AppUrl);
  } catch (error) {
    console.error('Failed to fetch applications:', error);
    return [];
  }
};

const handleLayoutConstruction = async (client: Auth0Client) => {
  showSpinner();

  if (!client) return;

  try {
    const authToken = await client.getTokenSilently();
    const applications = await fetchApplications(authToken);
    updateImportMap(applications);

    const user = await client.getUser();
    userEmail = user?.email ?? null;

    applications.forEach((app) => {
      registerApplication({
        name: app.Name,
        app: () => window.System.import(app.AppIdentifier || app.Name),
        activeWhen: [app.Route],
        customProps: {
          authToken,
          userEmail,
          handleLogout: async () =>
            client.logout({
              logoutParams: { returnTo: window.location.origin },
            }),
          domElementGetter: () =>
            app.AppIdentifier === '@becklar/common-navigation-ui'
              ? document.getElementById('nav-container')
              : document.getElementById('single-spa-application'),
        },
      });
    });

    start();

    const layoutEngine = constructLayoutEngine({
      routes: constructRoutes({
        routes: [{ type: 'route', path: '/*', routes: [] }],
      }),
      applications: [],
    });
    layoutEngine.activate();
  } catch (error) {
    console.error('Error during layout construction:', error);
  } finally {
    hideSpinner();
  }
};

async function initAuth0() {
  showSpinner();

  try {
    await loadRequiredModules();
    const client = await createAuth0ClientInstance();

    if (
      window.location.search.includes('code=') &&
      window.location.search.includes('state=')
    ) {
      await client.handleRedirectCallback();
      window.history.replaceState({}, document.title, window.location.pathname);
    }

    if (!(await client.isAuthenticated())) {
      await client.loginWithRedirect({
        authorizationParams: { redirect_uri: window.location.origin },
      });
    } else {
      await handleLayoutConstruction(client);
    }
  } catch (error) {
    console.error('Error initializing Auth0:', error);
  } finally {
    hideSpinner();
  }
}

initAuth0();

import axios from 'axios';
import { JwtHelper } from './jwt-encrypt';
import { generatePermissionsHash } from './hash-generator';

interface PermissionsPayload {
  data: any;
  hashedPermissions: string;
}

const PermissionsRetriever = async (
  authToken: string,
  userEmail: string,
  permissionsAPIUrl: string
): Promise<PermissionsPayload> => {
  if (!authToken) {
    throw new Error('Auth token is required.');
  }

  if (!userEmail) {
    throw new Error('User email is required.');
  }

  if (!permissionsAPIUrl) {
    throw new Error('Permissions API URL is required.');
  }

  const jwtHelper = new JwtHelper();
  const decryptedToken = jwtHelper.decodeToken(authToken.toString() || '');
  const tokenUserGroups = decryptedToken['https://becklar.com/groups'];

  const fetchPermissions = async (): Promise<PermissionsPayload> => {
    const requestData = {
      userEmail,
      groups: tokenUserGroups,
      apiKeyId: 123, // Replace with a dynamic or environment-sourced value if applicable
    };

    // const apiKey = process.env.LAMBDA_API_KEY;
    // const baseUrl = process.env.LAMBDA_URL;

    // try {
    //   const response = await axios.post(
    //     `https://jrcmzcweznjnmnekrtjogb7ra40lzbef.lambda-url.us-west-2.on.aws/permissions`,
    //     requestData,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${authToken}`,
    //         // 'x-api-key': 'Yd0o4O7UieXBSmj3JQUc1ACTr2wQ6HR4jnGZDpCc',
    //       },
    //       timeout: 5000, // Add a timeout of 5 seconds
    //     }
    //   );

    const data = {
      organizationPermissions: [
        {
          organizationId: 1,
          permissions: [2097217],
        },
        {
          organizationId: 2,
          permissions: [2097217],
        },
        {
          organizationId: 3,
          permissions: [2097217],
        },
      ],
      accountPermissions: [
        {
          accountId: 1,
          permissions: [2097217],
        },
      ],
      apiPermissions: [
        {
          organizationId: 1,
          permissions: [2097217],
        },
      ],
    };

    console.log('Permissions Data:', data);

    const hashedPermissions = generatePermissionsHash(data);

    return {
      data,
      hashedPermissions,
    };
  };

  return await fetchPermissions();
};

export default PermissionsRetriever;

import { registerApplication, start } from 'single-spa';
import { constructLayoutEngine, constructRoutes } from 'single-spa-layout';
import { Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js';
import axios from 'axios';
import { getLambdaUrlAndAPIKey } from './settings/settings';
import PermissionsRetriever from './permissions-retriever';

const showSpinner = () => {
  document
    .getElementById('loadingSpinner')
    ?.style.setProperty('display', 'block');
};

const hideSpinner = () => {
  const spinner = document.getElementById('loadingSpinner');
  if (spinner) {
    spinner.style.display = 'none';
  } else {
    console.warn('Spinner element not found');
  }
};

const getEnvironment = () => {
  const origin = window.location.origin;
  return origin.includes('.') ? origin.split('.')[1] : null;
};

const env = getEnvironment();
const { url: menuLambdaUrl, apiKey: menuApiKey } = getLambdaUrlAndAPIKey(env);

let auth0Client = null;
let userEmail = null;

const fetchAuth0Config = async () => {
  try {
    const cachedConfig = sessionStorage.getItem('auth0Config');
    if (cachedConfig) return JSON.parse(cachedConfig);

    const response = await axios.get(`${menuLambdaUrl}/app-credentials`, {
      headers: { 'x-api-key': menuApiKey },
    });

    if (response.status === 200) {
      sessionStorage.setItem('auth0Config', JSON.stringify(response.data));
      return response.data;
    } else {
      throw new Error(`Failed to fetch Auth0 config: ${response.statusText}`);
    }
  } catch (error) {
    console.error('Error fetching Auth0 config:', error);
    throw error;
  }
};

const createAuth0ClientInstance = async () => {
  if (auth0Client) return auth0Client;

  try {
    const config = await fetchAuth0Config();
    console.log('Auth0 config loaded:', config);

    const redirectUri = window.location.origin;

    auth0Client = await createAuth0Client({
      domain: 'prod-becklar-common.us.auth0.com',
      clientId: 'tIwmTg0uxCmBBBpZUYjPThTsg91BO6um',
      authorizationParams: {
        audience: 'becklar-permissions-api',
        redirect_uri: 'https://apps.becklar.com',
        organization: 'org_e5GEllMjBrWrQaNQ',
        scope: 'email becklar-one',
      },
      cacheLocation: 'localstorage', // Use localStorage instead of memory to persist across page refreshes
    });
    return auth0Client;
  } catch (error) {
    console.error('Error creating Auth0 client:', error);
    throw error;
  }
};

const fetchApplications = async (authToken) => {
  try {
    const startTime = performance.now();
    const response = await axios.get(`${menuLambdaUrl}/menu-items`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'x-api-key': menuApiKey,
      },
    });
    const endTime = performance.now();
    console.log(`Time taken to fetch applications: ${endTime - startTime}ms`);
    return response.data.filter((app) => app.AppUrl);
  } catch (error) {
    console.error('Error fetching applications:', error);
    throw error;
  }
};

const updateImportMap = (applications) => {
  try {
    const imports = applications.reduce((map, app) => {
      const appId = app.AppIdentifier || app.Name;
      map[appId] = localStorage.getItem(appId) || app.AppUrl;
      return map;
    }, {});
    console.log('Import map being added:', imports);
    window.System.addImportMap({ imports });
  } catch (error) {
    console.error('Error updating import map:', error);
    throw error;
  }
};

const handleLayoutConstruction = async (client) => {
  showSpinner();
  // Set a timeout but also clear it if the process completes before timeout
  const spinnerTimeout = setTimeout(() => {
    hideSpinner();
  }, 5000);

  try {
    // Get token once to avoid multiple calls
    const authToken = await client.getTokenSilently();

    const [user, applications] = await Promise.all([
      client.getUser(),
      fetchApplications(authToken),
    ]);

    userEmail = user.email || null;

    const { hashedPermissions, data } = await PermissionsRetriever(
      authToken,
      userEmail,
      menuLambdaUrl
    );

    // Update import map before registering applications
    updateImportMap(applications);

    // Ensure System.import is ready by waiting for the import map to be processed
    await new Promise((resolve) => setTimeout(resolve, 100));

    // Pre-load all applications to ensure they're available
    await Promise.all(
      applications.map((app) => {
        const appId = app.AppIdentifier || app.Name;
        return System.import(appId).catch((err) => {
          console.error('Failed to preload', appId, err);
        });
      })
    );

    applications.forEach((app) => {
      registerApplication({
        name: app.AppIdentifier || app.Name,
        app: () => {
          const importPromise = System.import(app.AppIdentifier || app.Name);
          if (app.AppIdentifier === '@becklar/reports') {
            return importPromise.then((mod) => mod.default);
          }
          return importPromise;
        },
        activeWhen: [app.Route],
        customProps: {
          authToken,
          userEmail,
          hashedPermissions,
          permissions: data,
          handleLogout: () =>
            client.logout({
              logoutParams: { returnTo: window.location.origin },
            }),
          domElementGetter: () =>
            app.AppIdentifier === '@becklar/common-navigation-ui'
              ? document.getElementById('nav-container')
              : document.getElementById('single-spa-application'),
        },
      });
    });

    start();

    constructLayoutEngine({
      routes: constructRoutes({
        routes: [{ type: 'route', path: '/*', routes: [] }],
      }),
      applications: [],
    }).activate();
  } catch (error) {
    console.error('Error during layout construction:', error);
    // Display error message to user
    const appContainer = document.getElementById('single-spa-application');
    if (appContainer) {
      appContainer.innerHTML =
        '<div class="error-message">An error occurred while loading the application. Please try refreshing the page.</div>';
    }
  } finally {
    // Clear the timeout to prevent hiding the spinner if it's already been hidden
    clearTimeout(spinnerTimeout);
    hideSpinner();
  }
};

const initAuth0 = async () => {
  showSpinner(); // Show spinner immediately when initialization starts

  try {
    // Load shell-ui first to ensure UI elements are available
    await System.import('@becklar/shell-ui');

    const client = await createAuth0ClientInstance();

    if (
      window.location.search.includes('code=') &&
      window.location.search.includes('state=')
    ) {
      await client.handleRedirectCallback();
      // Clean up the URL after handling the callback
      window.history.replaceState({}, document.title, window.location.pathname);
    }

    if (!(await client.isAuthenticated())) {
      await client.loginWithRedirect({
        authorizationParams: { redirect_uri: 'https://apps.becklar.com' },
      });
    } else {
      await handleLayoutConstruction(client);
    }
  } catch (error) {
    console.error('Error initializing Auth0:', error);
    // Display error message to user
    const appContainer = document.getElementById('single-spa-application');
    if (appContainer) {
      appContainer.innerHTML =
        '<div class="error-message">An error occurred while loading the application. Please try refreshing the page.</div>';
    }
  } finally {
    hideSpinner();
  }
};

// Initialize the application when the DOM is ready
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', initAuth0);
} else {
  initAuth0();
}
